import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ProductionHistoryPage = () => (
  <Layout>
    <SEO title="Script" />
    <h1>Script</h1>
    <p>
      <em>"It’s just not very exciting, is it? Concrete?"</em>
    </p>
    <p>
      <em>"But wait’ll you see what we can make out of it!"</em>
    </p>
    <p>
      Read an excerpt from the beginning of Scene 2 below.
    </p>
    <div
      className="excerpt"
      style={{
        padding: '40px',
        backgroundColor: '#f6f6e6',
        marginBottom: '20px',
      }}
    >
      <p>
        <em>
          Romulus and Remus stop dancing hurriedly, wipe sweat from their brows,
          and shoo the audience out of the way of the playing space wherever
          they’ve filled in.
        </em>
      </p>

      <strong>ROM.</strong>

      <p>
        <span>Hersilia!</span>
      </p>
      <strong>REM.</strong>

      <p>
        <span>What are you doing here?</span>
      </p>

      <strong>HER.</strong>

      <p>
        <span className="dialogue">
          What am I doing? What are you doing? Aren&rsquo;t we supposed to be
          traveling to the
        </span>
        <em>{' promised land'}</em>
        <span>&nbsp;or something!? This is a trash bin!</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>It&rsquo;s a theater.</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>It&rsquo;s a trash bin.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>Oh-kay!</span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>We&rsquo;re working &nbsp;on it.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>Hey, where&rsquo;s your husband?</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>You tell me.</span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>What does that mean?</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>It means I don&rsquo;t know where he is. Have you seen him?</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>We figured he was with you.</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span className="dialogue">Isn&rsquo;t that just like a</span>
        <em>{' man'}</em>
        <span>. Runs off to war and then runs off with whores.</span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>Well, I&rsquo;m sure it&rsquo;s not like that.</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span className="dialogue">
          Oh no? Please, tell me more about my husband&rsquo;s character. I know
          the role of &lsquo;wife&rsquo; pales in comparison to the role of
        </span>
        <em>{' general'}</em>
        <span>
          . I know him only as a husband, while you know him as a soldier.
        </span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>Very good point.</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>
          Yes! What can I know of a man I have seen only in his most vulnerable
          and private moments? How could I possibly attest to the character of a
          person with whom I share my bed, my body, my hearth and table? A man I
          have watched eat, for twelve years! Twelve years spent staring across
          the table at him dismantling potatoes between those big fucking
          chompers, as he tries to talk and eat at the same time, like an
          enormous baby with hard opinions about the deteriorating state of
          Thebes. I have only seen him cry over our third miscarriage, only seen
          him strike a wall in shame after his first infidelity, only seen him
          pray to the gods and humble himself before the vast
          incomprehensibility of space and time. Fuck, I&rsquo;m begging you,
          illuminate Hostus Hostilius for me, because I do not know him.
        </span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>Well-</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>What?</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>It sounds a little like you&rsquo;re being sarcastic?</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>
          Sarcasm is another thing I do not understand. Tell me of Hostus.
        </span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>What about him?</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>
          Where is he?! My lord, do you not possess like, object permanence, but
          for conversation?
        </span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>
          Probably he just needed some alone time after the battle. It was a
          pretty wild day.
        </span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>
          I got separated from my lieutenant when we stormed the palace --
          didn&rsquo;t see him again till halfway through the victory banquet.
          Turns out, he got knocked out in the kitchen larder and when he came
          to, he didn&rsquo;t know if the battle was still going on! He burst
          into the banquet hall, brandishing his sword, and yelling, &ldquo;Ah!
          Ah! Where is Amulius! Where is Amulius! What time is it!?&rdquo;
        </span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <em>(laughing)</em>
      </p>
      <p className="line">
        <span>Fuck, that&rsquo;s right.</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>And still, no Hostus.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>
          We don&rsquo;t know! We left Alba Longa yesterday afternoon and he
          wasn&rsquo;t with us then, and then we got separated from the army in
          the night, so if he&rsquo;s rejoined the company, we&rsquo;d have no
          way of knowing.
        </span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>
          Speaking of which, how on earth did you get so lost? The sun sets and
          you decide to, what, just wander off?
        </span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>Wandering is in our nature. We&rsquo;re used to wandering.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>Yeah, &ldquo;Not all who wander are lost.&rdquo;</span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>Who said that?</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>
          The point is we&rsquo;re not lost. We&rsquo;re exactly where
          we&rsquo;re supposed to be. Look! Look around? You see the hills?
        </span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>I see the hills.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>How many?</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>What?</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>How many hills are there?</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>Six.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <em>(infuriated)</em>
      </p>
      <p className="line">
        <span>NO!</span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>Geez.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span>Seven! There are seven hills!</span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>We just went over this as well.</span>
      </p>

      <strong>HER.</strong>

      <p className="line">
        <span>There are at least six.</span>
      </p>
      <strong>ROM.</strong>

      <p className="line">
        <span>What is wrong with you both?!?</span>
      </p>

      <strong>REM.</strong>

      <p className="line">
        <span>She&rsquo;s right, there are at least six.</span>
      </p>

      <strong>ROM.</strong>

      <p className="line">
        <span className="dialogue">
          BECAUSE THERE ARE SEVEN. THEY HAVE NAMES.
        </span>
      </p>
    </div>
  </Layout>
);

export default ProductionHistoryPage;
